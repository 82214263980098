<template functional>
    <div class="color-dot"
         v-bind:class="[data.class, data.staticClass, props.size]"
         v-bind:style="{ 'background-color': props.color }">
    </div>
</template>

<script>
export default {
    name: "ColorDot",
    props: {
        color: {
            type: String,
            default: "transparent"
        },
        size: {
            type: String,
            enum: ["sm", "md", "lg"],
            default: "md"
        }
    },
}
</script>

<style lang="scss" scoped>
.color-dot {
    display: inline-block;
    border-radius: 50%;

    &.sm {
        width: 12px;
        height: 12px;
    }

    &.md {
        width: 16px;
        height: 16px;
    }

    &.lg {
        width: 24px;
        height: 24px;
    }
}
</style>
